import React from 'react';
import { ReactComponent as Logo } from './logo-artevelde.svg';
import './logo.scss';

export default function LogoArtevelde() {
  return (
    <a href="https://www.arteveldehogeschool.be/" className="logo logo--artevelde" target="_blank" rel="noopener noreferrer">
      <Logo />
    </a>
  );
}
