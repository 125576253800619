import React from 'react';
import { ReactComponent as Logo } from './logo-kdg.svg';
import './logo.scss';

export default function LogoKdg() {
  return (
    <a href="https://www.kdg.be" className="logo logo--kdg" target="_blank" rel="noopener noreferrer">
      <Logo />
    </a>
  );
}
