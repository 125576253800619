import axios from 'axios';
import CONFIG from '../config';

export const getHelpContent = () => axios.get(`${CONFIG.API_BASE}/xapi/content/get/downloads`).then(res => res.data).then(data => {
  if (data && data.content && data.content.pictos) {
    data.content.pictos = data.content.pictos.map((picto, i) => {
      if (i === 0) {
        picto.icon = 'manual';
        picto.color = 'brand-1';
      }
      if (i === 1) {
        picto.icon = 'instructor';
        picto.color = 'brand-0';
      }
      if (i === 2) {
        picto.icon = 'steps';
        picto.color = 'brand-3';
      }
      return picto;
    });
  }

  return data;
});
