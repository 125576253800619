import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CONFIG from '../../../common/config';
import ROUTES from '../../../common/routes';
import { getHowDetailContent } from '../../../common/services/how.service';
import Button from '../../01_atoms/button/Button';
import Page from '../../04_layouts/page/Page';
import './problem.scss';

// const PROBLEM = {
//   'mama-van-jeroom': {
//     content: {
//       steps: [
//         {
//           title: 'De mama van Jeroom heeft nooit tijd',
//           content: `
//             <p>Aan het einde van de dag wil ik de ouders wat meer vertellen over hoe de dag van hun kind was. Eigenlijk kan dat perfect wanneer de ouders hun kinderen komen afhalen. Maar de mama van Jeroom heeft nooit tijd. ’s Morgens en ’s avonds is ze op 3 minuten binnen en buiten. Ze heeft precies niet zoveel interesse.</p>
//             <h3>Is dit herkenbaar?</h3>
//             <h3>Welke verklaringen hebben jullie daarvoor?</h3>
//           `,
//         },
//         {
//           title: 'De mama van Jeroom heeft nooit tijd',
//           content: `
//             <p>Lorem ipsum <strong>dolor</strong> sit <a href="#">amet</a>, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p>
//             <p><img src="https://placehold.it/1920x1080" alt="huge ass picture" /></p>
//           `,
//         }
//       ]
//     },
//   },
//   'papa-van-jeroom': {
//     content: {
//       steps: [
//         {
//           title: 'De papa van Jeroom heeft nooit tijd',
//           content: `
//             <p>Aan het einde van de dag wil ik de ouders wat meer vertellen over hoe de dag van hun kind was. Eigenlijk kan dat perfect wanneer de ouders hun kinderen komen afhalen. Maar de papa van Jeroom heeft nooit tijd. ’s Morgens en ’s avonds is hij op 3 minuten binnen en buiten. Hij heeft precies niet zoveel interesse.</p>
//             <h3>Is dit herkenbaar?</h3>
//             <h3>Welke verklaringen hebben jullie daarvoor?</h3>
//           `,
//         },
//         {
//           title: 'De papa van Jeroom heeft nooit tijd',
//           content: `
//             <p>Lorem ipsum <strong>dolor</strong> sit <a href="#">amet</a>, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p>
//             <p><img src="https://placehold.it/1920x1080" alt="huge ass picture" /></p>
//           `,
//         }
//       ]
//     },
//   },
// };

export default function Problem() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [ step, setStep ] = useState(0);
  const [ content, setContent ] = useState();
  const [ stepData, setStepData ] = useState(null);

  const init = async () => {
    const response = await getHowDetailContent(location.pathname);

    setContent(response.content);
  };

  useEffect(() => {
    if (!content) { return; }

    if (CONFIG.DEBUG) { console.log(content); }

    if (content.steps) {
      setStepData(content.steps[step]);
    }
  }, [content, step]);

  useEffect(() => {
    if (!params.slug) {
      history.replace(ROUTES.how.path);
      return;
    }

    init();
  }, []);
  return (
    <Page name="problem">
      {
        stepData ? (
          <>
            <h1 className="heading heading--1 problem__title fc--brand-1">{ stepData.title }</h1>

            <div className="problem__description" dangerouslySetInnerHTML={{ __html: stepData.content }} />

            <div className="problem__actions py--6 mt--5">
              { step === 0 && (<Button to={ROUTES.how.path} mode="round" size="small" color="secondary" border shadow label="Vorige" />) }
              { step > 0 && (<Button mode="round" size="small" color="secondary" border shadow label="Vorige" onClick={() => setStep(step - 1)} />) }
              { step < content.steps.length - 1 && (<Button mode="round" color="primary" border shadow label="Volgende" onClick={() => setStep(step + 1)} />) }
              { step === content.steps.length - 1 && (
                <>
                  <Button to={ROUTES.how.path} mode="round" color="positive" border shadow label="Nog een voorbeeld" className="ml--auto" />
                  <Button to={ROUTES.start.path} mode="round" color="primary" border shadow label="Doe het zelf" className="ml--5" />
                </>
              ) }
            </div>
          </>
        ) : null }
    </Page>
  )
}
