import React from 'react';
import Icon from '../../01_atoms/icons/Icon';
import './infolink.scss';

export default function Infolink(props) {
  const {
    pdf,
    link,
    icon,
    text,
    color,
  } = props;

  const Wrapper = ({ children }) => {
    const blank = link.indexOf(window.location.hostname) === -1;

    if (link) {
      return (<a href={link} className="infolink__link" target={blank ? '_blank' : '_self'} rel={blank ? 'noreferrer' : ''}>{children}</a>);
    }

    if (pdf) {
      return (<a href={pdf} className="infolink__link" target="_blank" rel="noreferrer">{children}</a>);
    }

    return (<div className="infolink__link">{children}</div>);
  }

  return (
    <div className={`infolink fc--${color}`}>
      <Wrapper>
        <>
          <div className="infolink__icon">
            <Icon icon={icon} />
          </div>
          <p className="heading heading--2">{text}</p>
        </>
      </Wrapper>
    </div>

  );
}
