import React from 'react';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as Instructor } from './instructor.svg';
import { ReactComponent as Manual } from './manual.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Print } from './print.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Steps } from './steps.svg';
import { ReactComponent as Chevron } from './chevron.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as Family } from './family.svg';
import { ReactComponent as Nursery } from './nursery.svg';
import { ReactComponent as People } from './people.svg';
import { ReactComponent as Think } from './think.svg';
import { ReactComponent as Close } from './close.svg';

import './icon.scss';

export default function Icon(props) {
  const {
    icon = '',
    className = '',
    ...restProps
  } = props;

  const renderIcon = () => {
    switch (icon) {
      case 'arrow': return <Arrow />;
      case 'info': return <Information />;
      case 'information': return <Information />;
      case 'instructor': return <Instructor />;
      case 'manual': return <Manual />;
      case 'plus': return <Plus />;
      case 'print': return <Print />;
      case 'save': return <Save />;
      case 'steps': return <Steps />;
      case 'chevron': return <Chevron />;
      case 'chevron-left': return <ChevronLeft />;
      case 'family': return <Family />;
      case 'nursery': return <Nursery />;
      case 'people': return <People />;
      case 'think': return <Think />;
      case 'close': return <Close />;
      default: return null;
    }
  }

  return (
    <span className={`icon icon--${icon} ${className}`} {...restProps}>
      { renderIcon() }
    </span>
  );
}
