import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import ROUTES from './common/routes';
import Help from './ui/05_buckets/help/Help';
import Home from './ui/05_buckets/home/Home';
import How from './ui/05_buckets/how/How';
import Start from './ui/05_buckets/start/Start';
import Header from './ui/03_organisms/header/Header';
import Problem from './ui/05_buckets/problem/Problem';
import Tool from './ui/05_buckets/tool/Tool';
import NoSleep from 'nosleep.js';
import General from './ui/05_buckets/general/General';

function App() {
  const history = useHistory();
  const noSleep = new NoSleep();

  history.listen(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    noSleep.enable();
  })

  return (
    <>
      <Header />
      <Switch>
        <Route path={ROUTES.home.path} exact>
          <Home />
        </Route>

        <Route path={ROUTES.how.path} exact>
          <How />
        </Route>

        <Route path={`${ROUTES.how.path}/:slug`} exact>
          <Problem />
        </Route>

        <Route path={ROUTES.start.path}>
          <Start />
        </Route>

        <Route path={ROUTES.tool.path}>
          <Tool />
        </Route>

        <Route path={ROUTES.help.path}>
          <Help />
        </Route>

        <Route path={`/:slug`}>
          <General />
        </Route>
      </Switch>
    </>
  );
}

export default App;
