import React, { useEffect, useState } from 'react';
import ROUTES from '../../../common/routes';
import { getHowContent } from '../../../common/services/how.service';
import Button from '../../01_atoms/button/Button';
import Example from '../../02_molecules/example/Example';
import Page from '../../04_layouts/page/Page';
import './how.scss';

export default function How() {
  const [ content, setContent ] = useState();

  const init = async () => {
    const response = await getHowContent();
    setContent(response.content);
  };

  useEffect(() => {
    init();
  }, []);

  return content ? (
    <Page name="how">
      <h1 className="heading heading--1 fc--brand-0">{ content.title }</h1>

      { content.intro ? (<div className="how__intro mb--5" dangerouslySetInnerHTML={{ __html: content.intro }} />) : null }

      <div className="examples">
        { content.examples.map((example) => (<Example {...example} key={`example--${example.id}`} />)) }
      </div>

      <div className="how__actions py--6 mt--10">
        <Button to={ROUTES.start.path} mode="round" color="primary" border shadow label="Doe het zelf" className="ml--5" />
      </div>
    </Page>
  ) : null;
}
