import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../common/routes';
import { getToolContent } from '../../../common/services/tool.service';
import Button from '../../01_atoms/button/Button';
import Textarea from '../../01_atoms/form/Textarea';
import Icon from '../../01_atoms/icons/Icon';
import Page from '../../04_layouts/page/Page';
import './start.scss';

// const ICONS = [ 'think', 'people', 'family', 'nursery' ];

export default function Start() {
  const history = useHistory();

  const [ content, setContent ] = useState();
  const [ message, setMessage ] = useState('');

  const init = async () => {
    const response = await getToolContent();

    setContent(response.start);
  };

  const onNext = () => {
    if (!message) { return; }

    history.push({
      pathname: ROUTES.tool.path,
      state: {
        message,
      },
    })
  };

  useEffect(() => {
    init();
  }, []);

  return content ? (
    <Page name="start">
      <h1 className="heading heading--1 fc--brand-0">{ content.tool_title }</h1>

      <div className="steps my--10">
        { content.steps.map((step, i) => (
          <React.Fragment key={`start-step-${i}`}>
          <div className="step">
            <div className="step__icon">
              <img src={step.icon} alt="" />
            </div>
            <p className="step__title">{ step.title }</p>
          </div>
          { i < content.steps.length - 1 && (<Icon icon="arrow" />) }
          </React.Fragment>
        )) }
      </div>

      <div className="start__input">
        <h2 className="heading heading--1 fc--brand-0">{ content.situation_titel }</h2>
        <div dangerouslySetInnerHTML={{__html: content.situation_intro}} className="p--1 mb--2" />

        <Textarea onChange={setMessage} />
      </div>

      <div className="start__actions py--6 mt--5">
        <Button mode="round" color="primary" label="Volgende" border shadow disabled={!message} className="ml--auto" onClick={onNext} />
      </div>
    </Page>
  ) : null;
}
