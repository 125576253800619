const CONFIG = {
  DEBUG: process.env.NODE_ENV === 'development',
  API_BASE: process.env.REACT_APP_API_BASE || '',
  TOOL_DEFAULT_RADIUS: 50,
  TOOL_DEFAULT_FONTSIZE: 16,
  TOOL_DEFAULT_STROKEWIDTH: 1,
  TOOL_DEFAULT_FILL: '#ffffff',
  TOOL_DEFAULT_STROKE: '#000000',
  TOOL_DEFAULT_COLOR: '#000000',
};

export default CONFIG;
