import React from 'react';
import LogoArtevelde from '../../01_atoms/logo/LogoArtevelde';
import LogoKdg from '../../01_atoms/logo/LogoKdg';
import LogoPace from '../../01_atoms/logo/LogoPage';
import './footer.scss';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content section">
        <div className="footer__icons">
          <div className="footer__icon">
            <LogoPace />
          </div>
          <div className="footer__icon">
            <LogoArtevelde />
          </div>
          <div className="footer__icon">
            <LogoKdg />
          </div>
        </div>
      </div>
    </footer>
  );
}
