import React, { useEffect } from 'react';
import { useState } from 'react';
import ROUTES from '../../../common/routes';
import { getHomeContent } from '../../../common/services/home.service';
import Button from '../../01_atoms/button/Button';
import Page from '../../04_layouts/page/Page';
import './home.scss';

export default function Home() {
  const [ step, setStep ] = useState(0);
  const [ content, setContent ] = useState();
  const [ stepData, setStepData ] = useState(null);

  const init = async () => {
    const response = await getHomeContent();
    setContent(response.content);
  };

  useEffect(() => {
    if (!content) { return; }
    if (content.steps) {
      setStepData(content.steps[step]);
    }
  }, [content, step]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Page name="home" bg>
      {
        stepData ? (
          <>
            <h1 className="heading heading--0 fc--brand-0">{ stepData.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: stepData.content }} />
            <div className="home__actions py--6 mt--5">
              { step > 0 && (<Button mode="round" color="secondary" label="Vorige" size="small" border shadow onClick={() => setStep(step - 1)} />)}
              { step < content.steps.length - 1 && (<Button mode="round" color="primary" label="Volgende" border shadow onClick={() => setStep(step + 1)} />) }
              { step === content.steps.length - 1 && (<Button to={ROUTES.how.path} mode="round" color="primary" label="Volgende" border shadow />) }
            </div>
          </>
        ) : null
      }
    </Page>
  );
}
