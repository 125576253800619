import React, { useEffect, useRef } from 'react';
import './form.scss';

export default function Textarea(props) {
  const inputRef = useRef();

  const {
    onChange = () => {},
    ...restProps
  } = props;

  const resize = () => {
    const { current: input } = inputRef;

    if (!input) { return; }

    input.removeAttribute('style');
    input.style.height = `${input.scrollHeight + 2}px`;
  };

  const onTextChange = (e) => {
    const { target: { value } } = e;

    // resize();

    onChange(value);
  };

  useEffect(() => {
    // resize();
  }, [inputRef]);

  return (
    <div className="form__item form__item--textarea">
      <textarea className="form__input form__input--textarea" onChange={onTextChange} ref={inputRef} {...restProps} />
    </div>
  );
}
