import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './button.scss';

export default function Button(props) {
  const {
    href = '',
    to = '',
    label,
    icon,
    mode = 'default', // default || round || naked
    size = 'normal', // normal || small || large
    color = 'primary', // primary || secondary || light
    className = '',
    border,
    shadow,
    ...restProps
  } = props;

  const button = useRef();

  const classes = ['button', `button--${mode}`, `button--${size}`, `button--${color}`, ...className.split(' ')].filter((c) => c);

  if (shadow) {
    classes.push('button--shadow');
  }

  if (border) {
    classes.push('button--border');
  }

  useEffect(() => {
    // force reflow after render
    button.current.style.display='none';
    // eslint-disable-next-line no-unused-expressions
    button.current.offsetHeight;
    button.current.style.display='';
  }, [shadow, border]);

  if (href) {
    return (
      <a ref={button} href={href} className={classes.join(' ')} {...restProps}>
        { icon && (<span className="button__icon">{ icon }</span>) }
        { label && (<span className="button__label">{ label }</span>) }
      </a>
    );
  }

  if (to) {
    return (
      <Link ref={button} to={to} className={classes.join(' ')} {...restProps}>
        { icon && (<span className="button__icon">{ icon }</span>) }
        { label && (<span className="button__label">{ label }</span>) }
      </Link>
    );
  }

  return (
    <button ref={button} className={classes.join(' ')} {...restProps}>
      { icon && (<span className="button__icon">{ icon }</span>) }
      { label && (<span className="button__label">{ label }</span>) }
    </button>
  );
}
