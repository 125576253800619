import React from 'react';
import LogoDroom from '../../01_atoms/logo/LogoDroom';
import Navigation from '../../02_molecules/navigation/Navigation';
import './header.scss';

export default function Header() {
  return (
    <header className="header">
      <div className="header__content section--full">
        <div className="header__logo">
          <LogoDroom />
        </div>
        <div className="header__nav">
          <Navigation />
        </div>
      </div>
    </header>
  );
}
