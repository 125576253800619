import React from 'react';
import './form.scss';

export default function Input(props) {
  const {
    className = '',
    type = 'text',
    label,
    id,
    ...restProps
  } = props;

  const classes = ['form__item', `form__item--${type}`];

  const labelAfter = type === 'checkbox' || type === 'radio';

  return (
    <div className={`${classes.join(' ')}${ className ? ` ${className}` : '' }`}>
      { label && !labelAfter ? (<label className="form__label" htmlFor={id}>{label}</label>) : null }
      <input className={`form__input form__input--${type}`} type={type} id={id} {...restProps} />
      { label && labelAfter ? (<label className="form__label" htmlFor={id}>{label}</label>) : null }
    </div>
  );
}
