import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ROUTES from '../../../common/routes';
import Button from '../../01_atoms/button/Button';
import IconMenu from '../../01_atoms/icons/IconMenu';
import './navigation.scss';

export default function Navigation() {
  const [ open, setOpen ] = useState(false);
  const location = useLocation();

  const [ path, setPath ] = useState(location.pathname);
  const [ activeLabel, setActiveLabel ] = useState();

  useEffect(() => {
    if (!location || !location.pathname) { return; }

    setPath(location.pathname);

    const route = Object.values(ROUTES).find(r => r.path === location.pathname);

    if (route) {
      setActiveLabel(route.label);
    } else {
      // tool route fix
      setActiveLabel(ROUTES.start.label);
    }

  }, [location.pathname]);

  return (
    <div className={`navigation__wrapper`}>
      <nav className={`navigation${open ? ' navigation--open' : ''}`}>
        <ul className="navigation__list">
          <li className="navigation__item">
            <NavLink to={ROUTES.home.path} exact className="navigation__link" activeClassName="navigation__link--active">{ROUTES.home.label}</NavLink>
          </li>
          <li className="navigation__item">
            <NavLink to={ROUTES.how.path} className="navigation__link" activeClassName="navigation__link--active">{ROUTES.how.label}</NavLink>
          </li>
          <li className="navigation__item">
            <NavLink to={ROUTES.start.path} isActive={() => path === ROUTES.start.path || path === ROUTES.tool.path} className="navigation__link" activeClassName="navigation__link--active">{ROUTES.start.label}</NavLink>
          </li>
          <li className="navigation__item">
            <NavLink to={ROUTES.who.path} className="navigation__link" activeClassName="navigation__link--active">{ROUTES.who.label}</NavLink>
          </li>
          <li className="navigation__item">
            <NavLink to={ROUTES.help.path} className="navigation__link" activeClassName="navigation__link--active">{ROUTES.help.label}</NavLink>
          </li>
        </ul>
        <div className="navigation__active">{ activeLabel }</div>
        <Button className="navigation__trigger" mode="round" color="light" shadow icon={<IconMenu className={open ? 'open' : ''} />} onClick={() => setOpen(!open)} />
      </nav>
    </div>
  );
}
