import React, { useEffect, useRef, useState } from 'react';
import Button from '../../01_atoms/button/Button';
import Input from '../../01_atoms/form/Input';
import Icon from '../../01_atoms/icons/Icon';
import './tooltip.scss';

const FILL = '#ccc';
const COLOR = '#666';

export default function Tooltip(props) {
  const {
    group,
    info,
    message,
    onClose,
    position,
    fade,
    onChange = () => {},
  } = props;

  const tipRef = useRef();

  const [ text, setText ] = useState();
  const [ checked, setChecked ] = useState();
  const [ showTop, setShowTop ] = useState();
  const [ top, setTop] = useState(position.y + (position.offsetY || 0));

  const onTextChange = (e) => {
    const { target: { value } } = e;

    message.text(value);
    message.offsetY(message.height() / 2);
    group.draw();

    setText(value);
  }

  const onDelete = (e) => {
    const stage = group.getStage();
    group.destroy();
    stage.draw();

    onClose();
  }

  const onCheck = (e) => {
    const { target: { checked } } = e;

    const circle = group.children[0];
    const text = [...group.children].find(child => child.className === 'text');

    if (checked) {
      circle.fill(FILL);
      if (text) {
        text.fill(COLOR);
      }
    } else {
      circle.fill(group.circle_fill);
      if (text) {
        text.fill(group.circle_color);
      }
    }

    group.getStage().batchDraw();

    setChecked(checked);

    onChange(e);
  };

  useEffect(() => {
    if (!tipRef.current) { return; }

    const tip = tipRef.current;
    const box = tip.getBoundingClientRect();

    const upTop = box.bottom + 20 > window.innerHeight;

    setShowTop(upTop);

    if (upTop) {
      setTop(position.y - (position.offsetY || 0));
    }

  }, [tipRef.current]);

  useEffect(() => {
    if (!group) { return; }
    setChecked(group.children[0].fill() === FILL);
  }, [group]);

  useEffect(() => {
    if (message && message.text()) {
      setText(message.text());
    }
  }, [message]);

  return (
    <div className={`tooltip${showTop ? ' tooltip--top' : ''}`} style={{ left: position.x, top }} ref={tipRef}>
      <div className="tooltip__content">
        <Button size="small" mode="naked" color="negative" className="tooltip__close" onClick={onClose} icon={<Icon icon="close" />} />
        <div dangerouslySetInnerHTML={{ __html: info }} className="mb--5" />

        { message ? (
          <Input value={text} defaultValue={text} onChange={onTextChange} label="Boodschap aanpassen" id="message" className="my--5" />
        ) : null }

        { fade !== undefined ? (<Input className="my--5" type="checkbox" label="Dit domein speelt een rol, maar we weten er niet veel over." checked={checked} id="questionable" onChange={onCheck} />) : null }

        { group ? (
          <div className="tooltip__actions mt--5">
            <Button className="mr--2" onClick={onClose} label="Ok" color="positive" size="small" />
            <Button onClick={onDelete} label="Verwijderen" color="negative" size="small" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
