import React from 'react';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import './example.scss';

export default function Example(props) {
  const { title, description, slug } = props;

  return (
    <div className="example">
      <div className="example__content">
        <h3 className="example__title fc--brand-1">{ title }</h3>
        <p className="example__description">{ description }</p>
      </div>
      <div className="example__actions">
        <Button to={`/${slug}`} mode="round" color="secondary" border shadow icon={<Icon icon="chevron" />} />
      </div>
    </div>
  );
}
