import React from 'react';
import Footer from '../../03_organisms/footer/Footer';
// import Header from '../../03_organisms/header/Header';
import './page.scss';

export default function Page(props) {
  const { name, bg, children } = props;

  const classes = ['page'];

  if (name) {
    classes.push(`page--${name}`);
  }

  if (bg) {
    classes.push(`page--bg`);
  }

  return (
    <div className={classes.join(' ')}>
      {/* <Header /> */}
      <div className="page__content section">
        { children }
      </div>
      <Footer />
    </div>
  );
}
