import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../../common/routes';
import { ReactComponent as Logo } from './logo-droom.svg';
import './logo.scss';

export default function LogoDroom() {
  return (
    <Link to={ROUTES.home.path} className="logo logo--droom">
      <Logo />
    </Link>
  );
}
