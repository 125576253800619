import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getGeneralContent } from '../../../common/services/general.service';
import Page from '../../04_layouts/page/Page';
import './general.scss';

export default function General() {
  const params = useParams();
  const [ content, setContent ] = useState();

  const init = async () => {
    const response = await getGeneralContent(params.slug);

    setContent(response.content);
  };

  useEffect(() => {
    init();
  }, []);
  return content ? (
    <Page name="general">
      <div className="richtext" dangerouslySetInnerHTML={{ __html: content.text }} />
    </Page>
  ) : null;
}
