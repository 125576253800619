const ROUTES = {
  home: {
    path: '/',
    label: 'Welkom',
  },
  how: {
    path: '/hoe-werkt-het',
    label: 'Hoe werkt het?',
  },
  general: {
    path: '/algemeen',
    label: '',
  },
  who: {
    path: '/wie-maakte-dit',
    label: 'Wie maakte dit?'
  },
  start: {
    path: '/doe-het-zelf',
    label: 'Doe het zelf',
  },
  tool: {
    path: '/tool',
    label: '',
  },
  help: {
    path: '/downloads',
    label: 'Downloads',
  },
};

export default ROUTES;
