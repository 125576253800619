import React, { useEffect, useState } from 'react';
// import ROUTES from '../../../common/routes';
import { getHelpContent } from '../../../common/services/help.service';
import Infolink from '../../02_molecules/infolink/Infolink';
import Page from '../../04_layouts/page/Page';
import './help.scss';

export default function Help() {
  const [ content, setContent ] = useState();

  const init = async () => {
    const response = await getHelpContent();
    setContent(response.content);
  };

  useEffect(() => {
    init();
  }, []);
  return content ? (
    <Page name="help">
      <h1 className="heading heading--1 fc--brand-0">{ content.title }</h1>

      <div className="mt--2 mb--5" dangerouslySetInnerHTML={{ __html: content.intro }} />

      <div className="infolinks mt--5">
        { content.pictos.map((picto, i) => (<Infolink key={`help--${i}`} {...picto} />)) }
      </div>
    </Page>
  ) : null;
}
