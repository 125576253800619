import React from 'react';
import './icon.scss';

export default function IconMenu(props) {
  const {
    className = ''
  } = props;
  return (
    <span className={`icon icon--menu ${className}`}>
      <span className="middle-bar"></span>
    </span>
  );
}
