import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Stage, Layer, Rect } from 'react-konva';
import Konva from 'konva';

import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import Page from '../../04_layouts/page/Page';

import './tool.scss';
import Tooltip from '../../02_molecules/tooltip/Tooltip';
import CONFIG from '../../../common/config';
import { getToolContent } from '../../../common/services/tool.service';
import Textarea from '../../01_atoms/form/Textarea';

const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#Getting_a_random_number_between_two_values
const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
}

export default function Tool() {
  const history = useHistory();
  const location = useLocation();

  const wrapperRef = useRef();
  const stageRef = useRef();
  const layerRef = useRef();
  const bgRef = useRef();

  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ message, setMessage ] = useState('');
  const [ open, setOpen ] = useState(0);
  const [ popup, setPopup ] = useState();
  const [ content, setContent ] = useState();
  const [ tools, setTools ] = useState();
  const [ exported, setExported ] = useState();

  const SCALEBY = .99;
  const MINSCALE = 1;
  const MAXSCALE = .5;

  let lastCenter = null;
  let lastDist = 0;

  const init = async () => {
    const response = await getToolContent();

    setContent(response.start);
    setTools(response.tools);

    onResize();
  };

  const addCircle = async (props) => {
    const {
      text = '',
      fill = CONFIG.TOOL_DEFAULT_FILL,
      textColor = CONFIG.TOOL_DEFAULT_COLOR,
      strokeWidth = CONFIG.TOOL_DEFAULT_STROKEWIDTH,
      fontSize = CONFIG.TOOL_DEFAULT_FONTSIZE,
      imageWidth = CONFIG.TOOL_DEFAULT_RADIUS,
      stroke = CONFIG.TOOL_DEFAULT_STROKE,
      radius = CONFIG.TOOL_DEFAULT_RADIUS,
      icon,
      info,
    } = props;

    if (CONFIG.DEBUG) { console.log('add circle', props); }

    setMenuOpen(false);
    setPopup();

    const stage = stageRef.current;
    const layer = layerRef.current;

    const group = new Konva.Group({
      draggable: true,
      width: radius,
      height: radius,
    });

    const w2 = stage.width() / 2;
    const h2 = stage.height() / 2;

    const x = getRandomArbitrary(w2 - 20, w2 + 20);
    const y = getRandomArbitrary(h2 - 20, h2 + 20);

    const circle = new Konva.Circle({
      x,
      y,
      radius,
      fill,
      stroke,
      strokeWidth,
    });

    group.add(circle);

    group.circle_fill = fill;
    group.circle_color = textColor;

    let message;

    if (text) {
      const textWidth = radius * 1.6;

      message = new Konva.Text({
        x,
        y: y - (icon ? radius / 2 : 0 ),
        text,
        width: textWidth,
        fill: textColor,
        fontSize,
        align: 'center',
        fontFamily: 'Source Sans Pro',
        wrap: true,
      });
      message.offsetX(message.width() / 2);
      message.offsetY(message.height() / 2);

      group.add(message);
    }

    let iconImage;

    if (icon) {
      const img = new Image();

      img.onload = function () {
        const ar = img.width / img.height;
        const width = imageWidth;

        if (CONFIG.DEBUG) { console.log('image width & ar:', width, ar); }

        iconImage = new Konva.Image({
          x,
          y: y + (text ? 10 : 0),
          image: img,
          width,
          height: width / ar,
        });

        iconImage.offsetX(iconImage.width() / 2);
        iconImage.offsetY(iconImage.height() / 2);

        // add the shape to the layer
        group.add(iconImage);
        group.draw();
      };
      // img.crossOrigin='Anonymous';
      img.src = icon;
    }

    group.on('click tap', () => {
      const stage = stageRef.current;
      const touchPos = stage.getPointerPosition();

      setPopup({
        info,
        circle,
        message,
        iconImage,
        group,
        fade: true,
        position: {
          x: touchPos.x,
          y: touchPos.y,
          offsetY: 28,
        },
      });
    });

    // add the shape to the layer
    layer.add(group);
    layer.batchDraw();
  };

  const onSave = (name, uri) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const prepCanvasForExport = () => {
    const layer = layerRef.current;
    const stage = stageRef.current;

    if (!layer) { return; }
    if (!stage) { return; }

    const orgScale = stage.scaleX();
    const orgX = stage.x();
    const orgY = stage.y();
    const orgHeight = stage.height();

    const padding = 20;

    let box = layer.getClientRect({ relativeTo: stage });

    const casus = new Konva.Label({
      x: box.x,
      y: box.y + box.height,
    });
    casus.add(new Konva.Tag({
      fill: '#f0f0f0',
    }));
    casus.add(new Konva.Text({
      text: message,
      fontSize: 30,
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
      fill: '#222222',
      wrap: true,
      padding,
      width: stage.width(),
    }));

    const offsetY = casus.height() + 50;

    casus.offsetY(-50);
    layer.add(casus);
    layer.draw();

    // box = layer.getClientRect({ relativeTo: stage });

    if (CONFIG.DEBUG) { console.log('show all', box, stage.width(), layer.width()); }

    const scale = Math.min(
      stage.width() / box.width,
      stage.height() / box.height,
    );

    stage.setAttrs({
      x: (-box.x) * scale,
      y: (-box.y) * scale,
      scaleX: scale,
      scaleY: scale,
      height: box.height + offsetY,
    });

    stage.batchDraw();

    resizeBackground();

    const uri = stage.toDataURL({
      // pixelRatio: Math.max(1, 1 / stageRef.current.scaleX()), // if zoomed out, higher resolution export
      mimeType: 'image/jpeg',
    });

    casus.remove();
    layer.draw();

    stage.setAttrs({
      x: orgX,
      y: orgY,
      scaleX: orgScale,
      scaleY: orgScale,
      height: orgHeight,
    });
    stage.batchDraw();

    resizeBackground();

    return uri;
  }

  const exportCanvas = async () => {
    const uri = prepCanvasForExport();

    onSave('Evaluatie', uri);
  }

  const printCanvas = async () => {
    const uri = prepCanvasForExport();

    setExported(uri);
    const img = new Image();
    img.src = uri;
    await img.decode();

    window.print();
  }

  const onWheel = (e) => {
    e.evt.preventDefault();

    const stage = e.currentTarget;

    const oldScale = stage.scaleX();

    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const relatedTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };

    const newScale = Math.max(MAXSCALE, Math.min(MINSCALE, e.evt.deltaY > 0 ? oldScale * SCALEBY : oldScale / SCALEBY));

    stage.scale({
      x: newScale,
      y: newScale
    });

    var newPos = {
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale,
    };

    stage.position(newPos);
    stage.batchDraw();

    resizeBackground();
  }

  const resizeBackground = () => {
    const stage = stageRef.current;
    const bg = bgRef.current;

    if (CONFIG.DEBUG) { console.log('stage scale', stage.scaleX()); }

    bg.width(stage.width());
    bg.height(stage.height());

    bg.scale({ x: 1 / stage.scaleX(), y: 1 / stage.scaleX() });
    bg.absolutePosition({ x: 0, y: 0 });

    stage.batchDraw();
  }

  const onTouchMove = (e) => {
    e.evt.preventDefault();

    const stage = e.currentTarget;

    let touch1 = e.evt.touches[0];
    let touch2 = e.evt.touches[1];

    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      let p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      let p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      let newCenter = getCenter(p1, p2);

      let dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      let pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      let scale = stage.scaleX() * (dist / lastDist);

      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      let dx = newCenter.x - lastCenter.x;
      let dy = newCenter.y - lastCenter.y;

      let newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();

      resizeBackground();

      lastDist = dist;
      lastCenter = newCenter;
    }
  }

  const onTouchEnd = () => {
    lastCenter = null;
    lastDist = 0;
  }

  const onDragMove = () => {
    resizeBackground();

    stageRef.current.batchDraw();
  };

  const onResize = () => {
    if (!wrapperRef.current) { return; }
    if (!stageRef.current) { return; }

    const wrapper = wrapperRef.current;
    const stage = stageRef.current;

    const width = wrapper.offsetWidth;
    const height = wrapper.offsetHeight;

    if (CONFIG.DEBUG) { console.log('set stage width', width, stage.width()); }

    stage.width(width);
    stage.height(height);

    if (CONFIG.DEBUG) { console.log('new width', stage.width()); }

    stage.batchDraw();

    resizeBackground();
  };

  const openSidebarPopup = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    const wrapper = target.closest('.item__link');
    const wbox = wrapper.getBoundingClientRect();

    const stage = stageRef.current.container();
    const sbox = stage.getBoundingClientRect();

    const x = wbox.left + (wbox.width / 2) - sbox.left;
    const y = wbox.top - sbox.top;

    setPopup({
      info: item.info,
      position: {
        x,
        y,
        offsetY: wbox.height,
      },
    });
  };

  const onCategoryClick = (e, i) => {
    setOpen(i);
    setPopup();
  }

  const onMenuToggleClick = (e) => {
    setMenuOpen(!menuOpen);
    setPopup();
  }

  const onMessageChange = (value) => {
    setMessage(value);
  }

  useEffect(() => {
    setPopup();
  }, [ menuOpen ]);

  useEffect(() => {
    if (!location.state) { return; }

    const { message } = location.state || {};

    setMessage(message || '');

    Konva.hitOnDragEnabled = true;
  }, [history, location.state]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    init();

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return content ? (
    <Page name="tool">
      <div className="tool__casus">
        <p className="p--2 bold">{ content.situation_titel }</p>
        <Textarea defaultValue={message} onChange={onMessageChange} placeholder={content.situation_intro} />
      </div>

      <div className="tool mt--4">
        <div className="tool__content" ref={wrapperRef}>
          <Stage
            width={1440}
            height={720}
            ref={stageRef}
            draggable
            className="tool__stage"
            onDragMove={onDragMove}
            onWheel={onWheel}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
          >
            <Layer ref={layerRef}>
              <Rect x={0} y={0} fill="white" width={1440} height={720} ref={bgRef} />
            </Layer>
          </Stage>

        </div>
        { popup && (
            <Tooltip {...popup} onClose={() => setPopup()} />
          ) }
        <div className="tool__actions fc--primary fs--h0">
          <Icon icon='print' onClick={printCanvas} className="fc--primary" />
          <Icon icon='save' onClick={exportCanvas} className="fc--positive" />
        </div>

        { exported ? (
          <div className="tool__export">
            <img src={exported} alt="Canvas export" />
          </div>
        ) : null }
      </div>

      <div className={`menu${menuOpen ? ' menu--open' : ''}`}>
        <div className="menu__trigger">
          <Button icon={<Icon icon="chevron-left" />} mode="round" color="dark" onClick={onMenuToggleClick} />
        </div>

        <div className="menu__accordeon">
          {
            tools && tools.map((tool, i) => (
              <div className={`accordeon__item${i === open ? ' accordeon__item--open' : ''}`} key={`tool--${i}`}>
                <div className={`item__trigger fc--text-light`} style={{ backgroundColor: tool.color }} onClick={(e) => onCategoryClick(e, i)}>
                  {/* <div className="item__number">{ i + 1 }</div> */}
                  <div className="item__meta">
                    <p className="item__title fs--large bold">{ tool.title }</p>
                    <p className="item__description fs--small">{ tool.description }</p>
                  </div>
                </div>
                <div className="item__links">
                  {
                    tool.items.map((item, j) => (
                      <div className="item__link" key={`item--${j}`} onClick={
                        () => addCircle({
                          text: item.label,
                          fill: item.fill,
                          textColor: item.color,
                          strokeWidth: item.strokeWidth,
                          fontSize: item.fontSize,
                          imageWidth: parseFloat(item.imageWidth || CONFIG.TOOL_DEFAULT_RADIUS),
                          icon: item.image,
                          info: item.info,
                          radius: parseFloat(item.radius || CONFIG.TOOL_DEFAULT_RADIUS),
                          stroke: item.stroke,
                        })}>
                        <div className="link__meta">
                          <div className="link__icon">
                            <img src={item.icon} alt="icon" />
                          </div>
                          <div className="link__label">{item.title}</div>
                        </div>
                        <div className={`link__actions`} style={{ color: tool.color }}>
                          <Icon icon="info" onClick={(e) => openSidebarPopup(e, item)} />
                          <Icon icon="plus" />
                        </div>

                        <div className="link__info">
                          <div dangerouslySetInnerHTML={{__html: item.info}} />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Page>
  ) : null
}
