import React, { createContext, useEffect, useState } from 'react';
const DroomContext = createContext({});

export default function DroomContextProvider(props) {
  const { children } = props;

  const [ loading, setLoading ] = useState(true);

  const init = async () => {
    setLoading(false);
  };

  const store = {
    loading,
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <DroomContext.Provider value={store}>
      { loading ? (<p>loading...</p>) : children }
    </DroomContext.Provider>
  )
}
